/** ********************************************************************************
 * G. Papaioannou
 * g_papaioannou@rocketmail.com
 * Mar, 2022
 *
 *
 *21-04-2022 : Added VERSION constant
 *Version 2.00 added the formcreator option and statistics
 *Version 2.10 added dynamic news
 *Version 2.20 added multiupload to dynamic news and published as a release
 *Version 2.30 added automatic Logout, Fixes in UserFilter and minor fixes
 *Version 2.40 added form preview, logout clock, session expired page and minor fixes


 02-12-2022 Added some Consts for the SessionTimeout and the casLogout & gsisLogout origin 
			are changed from /#/logout to /#/homepage because now we do the frontend logout before
			calling the backend.
 03-12-2022 Added SESSIONEXPIRED const and MAX_SESSION_INACTIVITY_TIME was set to 10
 26-04-2024 Upgraded to version 2.50
 * Version 2.50 : Added EMEP attributes, PIN to open secured forms, Edit by Admin, Form re-format, Email notifications, Direct Excel Download
 22-02-2025 Added casLogin & gsisLogin
 ********************************************************************************** */

export const VERSION = `2.50`;
export let PROJECT_URL = `${process.env.REACT_APP_CAS_SERVICE}/formio`;
export let API_URL = `${process.env.REACT_APP_CAS_SERVICE}/formio`;
export const API_URL_USER = `${process.env.REACT_APP_CAS_SERVICE}`;
export const API_URL_GSIS = `${process.env.REACT_APP_GSIS_APP_SERVICE}`;
export const API_GSIS_CLIENTID = `${process.env.REACT_APP_GSIS_CLIENTID}`;

export const FORMS_LIMIT = 10000;

const query = {};
window.location.search
	.substr(1)
	.split('&')
	.forEach(function(item) {
		query[item.split('=')[0]] = item.split('=')[1] && decodeURIComponent(item.split('=')[1]);
	});

PROJECT_URL = query.projectUrl || PROJECT_URL;
API_URL = query.apiUrl || API_URL;

export const AppConfig = {
	projectUrl: PROJECT_URL,
	apiUrl: API_URL,
	formsUrl: process.env.REACT_APP_CAS_SERVICE,
	apiUrlUser: API_URL_USER,
};

export const AuthConfig = {
	anonState: '/logout',

	casState: '/caslogout',
	casLogin: `${AppConfig.formsUrl}/auth/authenticate?redirectTo=/auth/connect?origin=`,
	casLogout: `${AppConfig.formsUrl}/auth/logout?origin=/#/homepage`,
	casLogoutExpired: `${AppConfig.formsUrl}/auth/logout?origin=/#/sessionexpired`,
	casEmailEnds: 'sch.gr',

	gsisLogout: `${API_URL_GSIS}/logout/${API_GSIS_CLIENTID}/?url=${AppConfig.formsUrl}/auth/gsis/logout?origin=/#/homepage`,
	gsisLogin: `${AppConfig.formsUrl}/auth/gsis/connect?origin=`,
	gsisLogoutExpired: `${API_URL_GSIS}/logout/${API_GSIS_CLIENTID}/?url=${AppConfig.formsUrl}/auth/gsis/logout?origin=/#/sessionexpired`,
	gsisState: '/gsislogout',
	gsisEmailEnds: 'gsis.gr',

	authState: '/',

	login: {
		form: 'user/login',
	},

	register: {
		form: 'user/register',
	},

	MAX_SESSION_INACTIVITY_TIME: 30, // minutes
	WARNING_TIME_BEFORE_LOGOUT: 1, // minutes
	LOGOUT: '/#/logout',
	HOMEPAGE: '/#/homepage',
	SESSIONEXPIRED: '/#/sessionexpired',
};

export const FormConst = {
	ARCHIVED: 'archived',
	PUBLISHED: 'published',
	SUBMITTED: 'submitted',
	DRAFT: 'draft',
	WORKING: 'working',
	EXPORT_PDF: 'YES',
	DONT_EXPORT_PDF: 'NO',
	// NUMBER_OF_SUBMISSIONS: 'numberOfSubmissions',
	SINGLE_SUBMISSION: 'Μοναδική Υποβολή',
	MULTIPLE_SUBMISSIONS: 'Πολλαπλές Υποβολές',
	NOTIFY_USER_BY_EMAIL: 'YES',
	DO_NOT_NOTIFY_USER_BY_EMAIL: 'NO',
	NOTIFY_ADMIN_BY_EMAIL: 'YES',
	DO_NOT_NOTIFY_ADMIN_BY_EMAIL: 'NO',
	FORM_CATEGORY_GENIKES: 'ΓΕΝΙΚΕΣ',
	FORM_CATEGORY_DIAGONISMWN_YPAITHA: 'ΔΙΑΓΩΝΙΣΜΩΝ ΥΠΑΙΘΑ',
	FORM_CATEGORY_DIOIKITIKWN: 'ΔΙΟΙΚΗΤΙΚΩΝ',
	FORM_CATEGORY_THRISKEYMATWN: 'ΘΡΗΣΚΕΥΜΑΤΩΝ',
	FORM_CATEGORY_ITHAGENEIAS: 'ΙΘΑΓΕΝΕΙΑΣ',
};

export const ExportConst = {
	DILOSI_SYNAINESIS: 'ΔΗΛΩΣΗ ΣΥΝΑΙΝΕΣΗΣ',
	USER_EMAIL: 'userEmail',
	USER_EMAIL_CONTACT: 'userEmailContact',
	USER_NAME: 'name',
	PROSKLISI_API_PROPERTY_NAME: 'prosklisi',
	DILOSI_SINAINESIS_API_PROPERTY_NAME: 'dilosiSinainesis',
	LIMIT: 1000,
	MSG_SUCCESS_DRAFT: 'Η φόρμα έχει αποθηκευτεί προσωρινά.',
	MSG_SUCCESS_SUBMITTED_WITH_PDF:
		'Η φόρμα έχει υποβληθεί οριστικά. Έχει μεταφορτωθεί αρχείο pdf ως αποδεικτικό της υποβολής σας.',
	MSG_SUCCESS_SUBMITTED_WITHOUT_PDF: 'Η φόρμα έχει υποβληθεί οριστικά.',
};

export const MessageYouHaventSaved = {
	MESSAGE_YOU_HAVENT_SAVED: 'Δεν έχετε αποθηκεύσει. Είστε βέβαιοι;',
};

export const Messages = {
	MSG_NOT_SAVED: 'Αν έχετε πραγματοποιήσει αλλαγές, αυτές δε θα αποθηκευτούν. Θέλετε να συνεχίσετε;',
};

export const NoFurtherSubmissionsForThisForm = {
	MSG_NO_FURTHER_SUBMISSIONS: "Έχετε υπερβεί τον μέγιστο αριθμό υποβολών γι' αυτή την φόρμα",
};

export const formsSortOptions = [
	{ value: 'properties.created', text: 'Ημερομηνία Δημιουργίας' },
	{ value: 'title', text: 'Τίτλος' },
	{ value: 'properties.customOwnerEmail', text: 'Email' },
	{ value: 'properties.status', text: 'Κατάσταση' },
];
