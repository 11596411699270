/******************************************************************************************
 * ListFormsUserSide
 *
 * * g_papaioannou@rocketmail.com
 * Apr 08, 2022
 *
 * Changed the strategy for fetching forms and User Data asychronous with more sophisticated
 * and proper way
 **
 * Debbuging the source code of the formio, I found that indexForms takes a 3rd parameter which
 * is a copy of the the fetched data when the promise has been fullfilled. This is undocumented.
 * The code added can be found at mapDispatchToProps and getForms.
 *
 * 03-12-2022 Removed the catch alerts from the fetchUser
 * 23-03-2023 Removed unnecessary code
 *            Added extra throw errors
 * 			  Fixed wrong comparison between String and Array of userData.BasicInfo.unit_description
 *
 ********************************************************************************************/

/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { indexForms, selectRoot, selectError, Errors } from 'react-formio';
import Loading from '../../containers/Loading';
import translateGR from '../../translate/translateGR';
//import FormGrid from '../../components/FormGrid';
import { FormConst } from '../../config';
import fetchUser from '../../helpers/fetchUser';
import singleSubmissionValidator from '../../helpers/singleSubmissionValidator';
import filterUserForms from '../../helpers/filterUserForms';
import NoValidUserData from '../../components/NoValidUserData';
import { AppConfig, FORMS_LIMIT } from '../../config';
import { Redirect } from 'react-router-dom';
import { NewFormGridUsers } from '../../components/NewFormGridUsers';

const FORMS_PAGESIZES = 100;
const FORMS_PAGINATION_PAGE = 1;
const FORMS_PAGINATION_NUMPAGES = 1;

/* const operations = [
	{
		action: 'userSubmissions',
		buttonType: 'primary',
		icon: 'pencil',
		permissionsResolver() {
			return true;
		},
		title: 'Υποβολή',
	},
]; */

const filterForms = user => {
	// console.log('FilterForms user: ', user);
	let filter = {};

	const basefilter = {
		type: 'form',
		'access.roles': user.roles,
	};

	// filter = {...basefilter, 'properties.status': FormConst.PUBLISHED};
	if (window.location.href.includes(FormConst.ARCHIVED)) {
		filter = { ...basefilter, 'properties.status': FormConst.ARCHIVED };
	} else {
		filter = { ...basefilter, 'properties.status': FormConst.PUBLISHED };
	}

	return filter;
};

/* const columns = [
	{
		key: 'title',
		title: 'Φόρμα',
		width: 8,
	},
	{
		key: 'operations',
		title: 'Λειτουργίες',
		width: 4,
	},
];
 */
const List = class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			error: '',
			formsToRenderArray: [],
			userData: null,
		};

		if (this.props.auth === undefined || this.props.auth.authenticated === false) {
			return;
		}

		this.ReadUserFromBackEnd = this.ReadUserFromBackEnd.bind(this);

		this.newListOfForms = null;
		this.formsToRenderArray = null;
		props.forms.isActive = true;
	}

	/*******************************************************************************************
	 * ReadUserFromBackEnd()
	 *
	 *  Calls backend to get the User's data
	 *
	 * 	{
	 *		"name": "ΠΑΠΑΙΩΑΝΝΟΥ ΓΕΩΡΓΙΟΣ",
	 *		"status": "ΕΚΠΑΙΔΕΥΤΙΚΟΣ",
	 *		"email": "sdancer@sch.gr",
	 *		"unit_description": "ΓΡΑΜΜΑΤΙΚΕΙΟΝ (ΓΥΜΝΑΣΙΟ ΠΤΕΛΕΟΣ)",
	 *		"fathername": "ΙΩΑΝΝΗΣ",
	 *		"branch": "ΠΕ86-ΠΛΗΡΟΦΟΡΙΚΗΣ"
	 *	}
	 *
	 *
	 *********************************************************************************************/
	ReadUserFromBackEnd() {
		const { forms } = this.props;

		/* let x = JSON.parse(localStorage.getItem("userData"))
		console.log("x userData = ", x, typeof x)  */

		fetchUser({ url: `${AppConfig.apiUrlUser}/api/user`, method: 'GET' })
			.then(res => {
				try {
					//console.log("RES in LIST, res", res)
					if (res.error) throw res.error;

					const userData = res.data;

					this.newListOfForms = { ...forms };

					const pagination = {
						page: FORMS_PAGINATION_PAGE,
						numPages: FORMS_PAGINATION_NUMPAGES,
					};
					this.newListOfForms.pagination = pagination;
					this.newListOfForms.limit = FORMS_LIMIT;
					this.newListOfForms.pageSizes = FORMS_PAGESIZES;

					if (!userData || !userData.BasicInfo.unit_description) throw new Error('Δεν βρέθηκαν τα βασικά δεδομένα του χρήστη');

					if ((userData.UnitInfo === undefined || userData.UnitInfo.length === 0) && userData.BasicInfo.unit_description.includes('ΠΣΔ')) throw new Error('Δεν βρέθηκαν τα δεδομένα του μητρώου χρήστη');

					this.formsToRenderArray = filterUserForms(userData, forms);
					this.newListOfForms.forms = this.formsToRenderArray;

					this.setState(prevState => {
						return {
							...prevState,
							userData: res.data,
						};
					});
				} catch (error) {
					console.log(`[ListFormsUserSide1] Προκλήθηκε σφάλμα κατά την επεξεργασία των δεδομένων που επεστράφησαν από τον διακομιστή.\n\nΠεριγραφή σφάλματος : [ ${error} ]\n\nΔοκιμάστε να κάνετε σύνδεση εκ νέου.`);
				}
			})
			.catch(err => {
				console.log(`[ListFormsUserSide2] Προκλήθηκε σφάλμα κατά την επεξεργασία των δεδομένων που επεστράφησαν από τον διακομιστή.\n\nΠεριγραφή σφάλματος : [ ${err} ]\n\nΔοκιμάστε να κάνετε σύνδεση εκ νέου.`);
			});
	}

	componentDidMount() {
		const { auth, forms, getForms } = this.props;

		if (auth === undefined || auth.authenticated === false) {
			return;
		}

		forms.limit = FORMS_LIMIT;
		forms.pageSizes = FORMS_PAGESIZES;
		forms.pagination.page = FORMS_PAGINATION_PAGE;
		forms.pagination.numPages = FORMS_PAGINATION_NUMPAGES;

		getForms(1, filterForms(auth.user), this.ReadUserFromBackEnd);
	}

	onPageSizeChanged(page) {
		const { getForms, forms, auth } = this.props;
		forms.limit = page;
		forms.pageSizes = 10;
		getForms(1, filterForms(auth.user), this.ReadUserFromBackEnd);
	}

	getFormsWithQuery(page) {
		const { getForms, auth } = this.props;
		getForms(page, filterForms(auth.user), this.ReadUserFromBackEnd);
	}

	ShowFormGrid = () => {
		const { /* onAction, */ errors } = this.props;
		//const pageSizes = ['all'];

		return (
			<div>
				<Errors errors={errors} />
				<h3>Φόρμες</h3>
				<hr />
				<NewFormGridUsers forms={this.newListOfForms}/>
				{/* <FormGrid forms={this.newListOfForms} operations={operations} onAction={onAction} onPageSizeChanged={page => this.onPageSizeChanged(page)} getForms={page => this.getFormsWithQuery(page)} columns={columns} pageSizes={pageSizes} /> */}
			</div>
		);
	};

	render() {
		const { auth } = this.props;

		if (auth === undefined || auth.authenticated === false) {
			return <Redirect to="/" />;
		}

		const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
		const userData = this.state.userData;
		const { forms } = this.props;

		if (forms.isActive || !userData) {
			return (
				<React.Suspense fallback={loading()}>
					<Loading />
				</React.Suspense>
			);
		}

		if (userData && !(userData.UnitInfo === undefined || userData.UnitInfo.length === 0)) {
			return this.ShowFormGrid();
		} else if (userData && userData.BasicInfo.unit_description === 'GSIS') {
			return this.ShowFormGrid();
		} else {
			return (
				<div>
					<NoValidUserData />
				</div>
			);
		}
	}
};

const mapStateToProps = state => {
	const auth = selectRoot('auth', state);
	if (auth === undefined || auth.authenticated === false) return { auth };

	return {
		forms: selectRoot('forms', state),
		errors: selectError('forms', state),
		options: { language: 'en', i18n: JSON.parse(translateGR) },
		auth: auth,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getForms: (page, query, func) => {
			dispatch(
				indexForms('forms', page, query, (err, result) => {
					if (err === null) {
						func();
					}
				}),
			);
		},
		onAction: (form, action) => {
			switch (action) {
				case 'userSubmissions':
					let singleSubmission = singleSubmissionValidator(form);

					if (singleSubmission) {
						dispatch(push(`/form/${form._id}/submission`));
					} else {
						dispatch(push(`/form/${form._id}/userSubmissions`));
					}
					//
					// dispatch(push(`/form/${form._id}/submission`));
					// dispatch(push(`/form/${form._id}/userSubmissions`));
					break;
				case 'view':
					if (!(form.properties.status === 'archived')) {
						dispatch(push(`/form/${form._id}`));
					}
					break;
				case 'submission':
					dispatch(push(`/form/${form._id}/submission`));
					break;
				case 'edit':
					dispatch(push(`/form/${form._id}/edit`));
					break;
				// case 'export':
				// 	dispatch(push(`/form/${form._id}/export`));
				// 	break;
				case 'delete':
					dispatch(push(`/form/${form._id}/delete`));
					break;
				default:
			}
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
