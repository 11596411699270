/* *************************************************************************
>> edit.js Component


 g. papaioannou (g_papaioannou@rocketmail.com)
 26-09-2023 : Added submission edit by Admin/FormCreator
 13-02-2024 - Disabled Custom Option CSS to the Form builder at line 42
 
 ************************************************************************* */

/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { selectRoot, resetSubmissions, saveSubmission, selectError, Errors } from 'react-formio';
import { push } from 'connected-react-router';
import { AppConfig } from '../../../../../config';
import Loading from '../../../../../containers/Loading';
import Form from '../../../../../components/Form';
import asyncFetch from '../../../../../helpers/helperFunctions';
import exportSubmissionDataPDF from './exportSubmissionDataPDF';
import { toast } from 'react-toastify';
import { ExportConst, FormConst, NoFurtherSubmissionsForThisForm } from '../../../../../config';
// import { Prompt } from 'react-router-dom'
import StayOnPage from '../../../../../helpers/StayOnPage';
import { RouteLeavingGuard } from '../../../../../helpers/RouteLeavingGuard';
import cleanDataBeforeSubmission from '../../../../../helpers/cleanDataBeforeSubmission';
import datesAreOnSameDay from '../../../../../helpers/datesAreOnSameDay';
import singleSubmissionValidator from '../../../../../helpers/singleSubmissionValidator';
import updateUserMetaData from './updateUserMetaData';
import { Redirect } from 'react-router-dom';
import { Button, Card, CardTitle, CardText, Badge } from 'reactstrap';
import checkRequiredFields from '../../../../../helpers/checkRequiredFields';
import { sendEmailToAdmin, sendEmailToUser, sendEmailToUserAfterAdminsEdit } from '../../../../../helpers/sendEmails';

const notify = (msg, sec) => {
	toast.success(msg, {
		position: 'top-right',
		autoClose: sec,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: false,
		draggable: true,
		progress: undefined,
	});
};

let stayOnPage = new StayOnPage();

class Edit extends PureComponent {
	render() {

		const { auth } = this.props;

		if (auth === undefined || auth.authenticated === false) {
			return <Redirect to="/" />;
		}

		const {
			connecteduser,
			hideComponents,
			onSubmit,
			// options,
			errors,
			form: { form, isActive: isFormActive },
			submission: { submission, isActive: isSubActive, url },
			submissions,
			history,
		} = this.props;

		const styleNoFurtherSubsMsg = {
			color: '#f86c6b',
		};

		//******** Check Single or Multiple Submission on Edit Component
		let singleSubmission = singleSubmissionValidator(form);

		let flagToCutSubmissionOnSingleSubForm = false;
		if (isFormActive || isSubActive) {
			return <Loading />;
		}

		if (auth.user.data.selectedAdminRole !== 'adminRole' && auth.user.data.selectedAdminRole !== 'formbuilderRole') {
			for (let i = 0; i < submissions.submissions.length; i++) {
				let element = submissions.submissions[i];
				if (element.metadata.state === 'submitted' && singleSubmission) {
					flagToCutSubmissionOnSingleSubForm = true;
					break;
				}
			}
		}
		//console.log("flagToCutSubmissionOnSingleSubForm", flagToCutSubmissionOnSingleSubForm)
		//******** Check Single or Multiple Submission on Edit Component

		return (
			<div>
				{(auth.user.data.selectedAdminRole === 'adminRole' || auth.user.data.selectedAdminRole === 'formbuilderRole') && (
					<Card body>
						<CardTitle tag="h5">Επεξεργασία Υποβληθείσας Αίτησης</CardTitle>
						<CardText>
							Η Επεξεργασία της Αίτησης θα την επεναφέρει σε κατάσταση "Προσωρινής Υποβολής". Θα πρέπει να γίνει <strong>Αποθήκευση</strong> για την οριστικοποίηση των αλλαγών
							<br />
							<br />
							<Badge color="danger" pill>
								ΠΡΟΣΟΧΗ
							</Badge>
							Την αποκλειστική ευθύνη για οποιαδήποτε αλλαγή της αίτησης, την φέρνει αποκλειστικά ο υπεύθυνος επεξεργασίας της αίτησης.
						</CardText>
						<Button color="danger" disabled={submission.metadata.state === 'draft'} onClick={() => this.props.onRevertSubmitionStatus(submission, 'draft', auth.user.data.email, form)}>
							Επεξεργασία
						</Button>
						<p></p>
						<Button color="dark" disabled={submission.metadata.state === 'submitted'} onClick={() => this.props.onRevertSubmitionStatus(submission, 'submitted', auth.user.data.email, form)}>
							Αποθήκευση
						</Button>
					</Card>
				)}

				{/* <Prompt when={stayOnPage.getStay() === true} message={MessageYouHaventSaved.MESSAGE_YOU_HAVENT_SAVED} /> */}
				{!flagToCutSubmissionOnSingleSubForm ? (
					<RouteLeavingGuard
						// When should shouldBlockNavigation be invoked,
						// simply passing a boolean
						// (same as "when" prop of Prompt of React-Router)
						when={stayOnPage.getStay() === true}
						// Navigate function
						navigate={path => history.push(path)}
						userRole={auth.user.data.selectedAdminRole}
						formID={form._id}
						// Use as "message" prop of Prompt of React-Router
						shouldBlockNavigation={location => {
							// This case it blocks the navigation when:
							if (location.pathname === `/form/${form._id}/submission/${submission._id}/delete` || location.pathname === `/form/${form._id}/submission/${submission._id}/edit`) {
								return false;
							} else {
								return true;
							}
						}}
					/>
				) : (
					<div></div>
				)}
				<h4>Επεξεργασία: {form.title}</h4>
				{/* <h4 style={{ color: '#20a8d8' }}> Η Φόρμα λήγει στις: {dateToDisplay} - 23:59</h4> */}
				<hr />
				<Errors errors={errors} />
				<h5>{submission.metadata.state === FormConst.SUBMITTED ?
					<Badge color="success">Τελική Υποβολή</Badge>
					:
					<Badge color="warning">Προσωρινή Υποβολή</Badge>
				}</h5>
				{!flagToCutSubmissionOnSingleSubForm ?
					<Form form={form}
						submission={submission}
						url={url}
						hideComponents={hideComponents}
						//onSubmit={onSubmit}
						onSubmit={submission => onSubmit(submission, connecteduser)}
						// options={{ ...{ template: 'bootstrap3', iconset: 'fa' }, ...options }} 
						/> :
					<h5 style={styleNoFurtherSubsMsg}>{NoFurtherSubmissionsForThisForm.MSG_NO_FURTHER_SUBMISSIONS}</h5>}
			</div>
		);
	}
}

const mapStateToProps = state => {
	const auth = selectRoot('auth', state);
	if (auth === undefined || auth.authenticated === false) return { auth };

	return {
		auth: auth,
		form: selectRoot('form', state),
		connecteduser: selectRoot('connecteduser', state),
		submission: selectRoot('submission', state),
		submissions: selectRoot('submissions', state),
		options: {
			noAlerts: true,
		},
		errors: [selectError('submission', state), selectError('form', state)],
	};
};

const validateFormMetaData = async formid => {
	const form = await asyncFetch({
		url: `${AppConfig.apiUrl}/form/${formid}`,
		method: 'GET',
	});
	return form;
	// TODO check dates and throw error if date not in boundaries
};

const setSubmissionStatus = (dispatch, submission, ownProps, form, connecteduser) => {
	const userData = localStorage.getItem('userData');
	let userDataLocal = null;
	if (userData !== 'undefined') {
		/* console.log("userData", userData)
		console.log("user") */
		userDataLocal = JSON.parse(localStorage.getItem('userData'));
	}

	if (submission.data.saveDraft !== undefined) {
		submission.metadata.state = submission.state;
	}

	if (userDataLocal) {
		submission.data = updateUserMetaData(submission.data, userDataLocal);
	}

	submission.data = cleanDataBeforeSubmission(submission.data);

	dispatch(
		saveSubmission('submission', submission, ownProps.match.params.formId, (err, savedSubmission) => {
			checkRequiredFields(form, submission);
			if (!err) {
				// dispatch(resetSubmissions('submission'));
				// dispatch(push(`/form/${ownProps.match.params.formId}/submission/${savedSubmission._id}` ));

				var msg = '---';
				var sec = 6000;

				if (savedSubmission.metadata.state === FormConst.SUBMITTED) {
					//afora palioteres formes poy den eixe dilothei to exprotPDF
					if (form.properties.exportPDF === undefined) {
						form.properties.exportPDF = FormConst.EXPORT_PDF;
					}

					//afora palioteres formes poy den eixe dilothei to numberOfSubmissions
					if (form.properties.numberOfSubmissions === undefined) {
						form.properties.numberOfSubmissions = FormConst.MULTIPLE_SUBMISSIONS;
					}

					//CHECK EMAILS
					//User Email
					if (form.properties.sendUserEmail === undefined) {
						form.properties.sendUserEmail = FormConst.DO_NOT_NOTIFY_USER_BY_EMAIL;
					} else if (form.properties.sendUserEmail === 'YES') {
						//gsis custom email contact
						const searchObject = form.components.find((el) => el.key === "userEmailContact");
						//console.log("searchObject", searchObject)
						if (searchObject) {
							sendEmailToUser(form.title, savedSubmission._id, savedSubmission.data[searchObject.key])
							//gsis custom email contact
						} else {
							sendEmailToUser(form.title, savedSubmission._id, savedSubmission.data.userEmail)
						}
					}

					//Admin Email
					if (form.properties.sendAdminEmail === undefined) {
						form.properties.sendAdminEmail = FormConst.DO_NOT_NOTIFY_ADMIN_BY_EMAIL;
					} else if (form.properties.sendAdminEmail === 'YES') {
						sendEmailToAdmin(form, form.properties.customOwnerEmail, savedSubmission, connecteduser)
					}
					//CHECK EMAILS

					if (form.properties.exportPDF === FormConst.EXPORT_PDF) {
						msg = ExportConst.MSG_SUCCESS_SUBMITTED_WITH_PDF;
						sec = 6000;

						exportSubmissionDataPDF(savedSubmission);
					} else {
						msg = ExportConst.MSG_SUCCESS_SUBMITTED_WITHOUT_PDF;
						sec = 4000;
					}
				} else if (savedSubmission.metadata.state === FormConst.DRAFT) {
					msg = ExportConst.MSG_SUCCESS_DRAFT;
					sec = 4000;
				}
				stayOnPage.setStay(false);
				notify(msg, sec);
				dispatch(resetSubmissions('submission'));
				dispatch(push(`/form/${ownProps.match.params.formId}/submission/${savedSubmission._id}`));
				stayOnPage.setStay(true);
			}
		}),
	);
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onSubmit: async (submission, connecteduser) => {
			try {
				const tempForm = await validateFormMetaData(ownProps.match.params.formId);
				const formEndDate = new Date(tempForm.properties.endDate);
				const currentDate = new Date();
				if (formEndDate < currentDate && !datesAreOnSameDay(formEndDate, currentDate)) {
					throw new Error('Έχει λήξει η περίοδος υποβολής της αίτησης');
				}

				if (submission.state === FormConst.SUBMITTED && submission.data.userEmail === '') {
					// fetch user
					asyncFetch({
						url: `${AppConfig.apiUrl}/current`,
						method: 'GET',
					}).then(
						user => {
							// save user data to submission
							submission.data.userEmail = user.data.email;
							setSubmissionStatus(dispatch, submission, ownProps, tempForm, connecteduser);
						},
						error => {
							console.log(error);
							throw error;
						},
					);
					return;
				}
				setSubmissionStatus(dispatch, submission, ownProps, tempForm, connecteduser);
			} catch (error) {
				alert(error.message);
			}
		},
		onRevertSubmitionStatus: async (submission, state, adminEmail, form) => {
			try {
				submission.metadata.state = state;
				submission.metadata.editedByAdmin = true; //just for info
				submission.metadata.editAdminEmail = adminEmail; // just for info
				submission.metadata.editedDate = new Date().toJSON(); //just of info
				submission.data.editedbyadmin = true; //this is critical.
				//To make visible any other component after submission you must create a hideden component and check this field update i.e.
				//Under simple condition of any component just pick true for Display when editedbyadmin has the value true

				if (state === 'submitted') {
					//gsis custom email contact
					const searchObject = form.components.find((el) => el.key === "userEmailContact");
					//console.log("searchObject", searchObject)
					if (searchObject) {
						sendEmailToUserAfterAdminsEdit(form.title, submission._id, submission.data[searchObject.key])
						//gsis custom email contact
					} else {
						sendEmailToUserAfterAdminsEdit(form.title, submission._id, submission.data.userEmail)
					}
				}

				dispatch(
					saveSubmission('submission', submission, ownProps.match.params.formId, (err, savedSubmission) => {
						if (!err) {
							var msg = '---';
							var sec = 6000;

							if (savedSubmission.metadata.state === FormConst.SUBMITTED) {
								msg = ExportConst.MSG_SUCCESS_SUBMITTED_WITHOUT_PDF;
								sec = 4000;
							} else if (savedSubmission.metadata.state === FormConst.DRAFT) {
								msg = ExportConst.MSG_SUCCESS_DRAFT;
								sec = 4000;
							}
							notify(msg, sec);
						}
					}),
				);
			} catch (error) {
				alert(error.message);
			}
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
