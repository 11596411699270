/* *************************************************************************
>> App.js Component


 g. papaioannou (g_papaioannou@rocketmail.com)

 Feb 2022

 03-12-2022 SessionTimeout Component added
 
 ************************************************************************* */

import React from 'react';
import { Route } from 'react-router-dom';
import Form from './views/Form';
import Auth from './views/Auth/Auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import ListArchived from './views/Form/ListArchived';
import SessionTimeout from './components/SessionTimeout';

import { HashRouter, Switch } from 'react-router-dom';
import './App.scss';

import { selectRoot } from 'react-formio';
import { connect } from 'react-redux';

import ServerMaintenanceStatus from './serverMaintenance';
import { setMaintenance } from './redux/actions/maintenanceActions';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
// const Login = React.lazy(() => import('./views/Pages/Login'));
// const Register = React.lazy(() => import('./views/Pages/Register'));
// const Page404 = React.lazy(() => import('./views/Pages/Page404'));
// const Page500 = React.lazy(() => import('./views/Pages/Page500'));

toast.configure();

class App extends React.Component {
	componentDidMount() {
		setInterval(() => {
			//Read maintenance from BackEnd Server
			ServerMaintenanceStatus().then(result => {
				const ServerMaintenanceValue = JSON.stringify(result) === 'true';

				//Update Redux State
				if (this.props.MaintanenceEnabled !== ServerMaintenanceValue)
					this.props.setMaintenanceValue(ServerMaintenanceValue); //

				//show notifications only for the logged users. Don't give a shit for the others, they can't login anyway (haha suckers !!!)
				if (ServerMaintenanceValue === true && this.props.auth.user != null) {
					(() =>
						toast.error(
							'Το σύστημα τέθηκε σε κατάσταση συντήρησης.Παρακαλώ αποθηκεύστε άμεσα την εργασία σας και αποσυνδεθείτε για να μην υπάρξει απώλεια δεδομένων.',
						))();
				} else {
					return;
				}
			});
		}, 30000);
	}

	render() {
		return (
			<div>
				<ToastContainer
					containerId="maintenanceID"
					limit={1}
					pauseOnHover
					closeOnClick
					autoClose={5000}
					position="top-right"
					enableHtml={true}
					style={{ width: '790px' }}
					theme={'light'}
					pauseOnFocusLoss
				/>
				<SessionTimeout />
				<HashRouter>
					<React.Suspense fallback={loading()}>
						<Switch>
							{/* <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} /> */}
							{/* <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} /> */}
							{/*App.js-->DefaultLayout.js-->[DefaultHeader.js, Home.js(alias homepage via routes) calls Hero.js -if NOT authenticated- or HomePage.js -if authenticated-, DefaultFooter] */}

							<Route
								path="/"
								name="Home"
								// component={DefaultLayout}
								render={props => <DefaultLayout {...props} />}
							/>
							<Route path="/form" component={Form} />
							<Route path="/auth" component={Auth} />
							{/* <Route path="/archived" component={ListArchived} /> */}
						</Switch>
					</React.Suspense>
				</HashRouter>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		auth: selectRoot('auth', state),
		MaintanenceEnabled: state.maintenance.maintenance,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setMaintenanceValue: value => dispatch(setMaintenance(value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
