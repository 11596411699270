/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import { FormEdit, FormBuilder } from 'react-formio';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import Select from 'react-select';
import { Button, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { /* UncontrolledCollapse, */ Card } from 'reactstrap';
import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';
import _camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import el from 'date-fns/locale/el';
// import { compose } from 'redux';
import './css/DatePicker.css';
import './css/PinInput.css'
import { FormConst } from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes"
import options_specialties from "../data/specialties";
import options_regions from "../data/regions";
//import options_transfer_regions from '../data/regions';

import { removeTimeFromDate, set2359Hours } from "../helpers/updateStartAndEndFormDates"
import addDefaultCSSToSelectBoxesAndRadios from "../helpers/addDefaultCSSToSelectBoxesAndRadios"
import addDefaultCSSToFileFields from "../helpers/addDeafultCSSToFileFields"
import { userEmailExists, userNameExists, userEmailContactExists } from '../helpers/userEmailAndNameExists';
import $ from 'jquery'

registerLocale('el', el);

const getRoleOptions = role => {
	const [name, value] = role;
	return { value: value._id, label: name };
};

export default class YpepthFormEdit extends FormEdit {
	constructor(props) {
		super(props);

		this._isMounted = false;

		const { form, roles, formSelectedRoles } = props;

		const rolesArr = Object.entries(roles);
		let selectedRoles;
		// if (form._id!==undefined) {
		// console.log(formSelectedRoles);
		if (formSelectedRoles !== undefined) {
			selectedRoles = rolesArr
				.filter(role => {
					// eslint-disable-next-line
					const [name, value] = role;
					const res = formSelectedRoles[0].roles.filter(formRole => {
						if (formRole === value._id) return true;
						return false;
					});
					return res.length;
				})
				.map(getRoleOptions);
		} else {
			// selectedRoles = rolesArr.map(getRoleOptions);
			selectedRoles = null;
		}

		if (form.properties === undefined) {
			form.properties = {
				status: FormConst.WORKING,
				startDate: new Date(),
				endDate: new Date(),
				exportPDF: FormConst.EXPORT_PDF,
				recievers: new Array(0),
				recieversRegions: new Array(0),
				recieversPerfectures: new Array(0),
				recieversTeacherBranches: new Array(0),
				tempSelectedPerfectures: new Array(0),
				sendUserEmail: FormConst.DO_NOT_NOTIFY_USER_BY_EMAIL,
				sendAdminEmail: FormConst.DO_NOT_NOTIFY_ADMIN_BY_EMAIL,
				//numberOfSubmissions: FormConst.SINGLE_SUBMISSION
			};
		}

		this.state = {
			selectedRoles,
			selectedOption: null,
			form: form
				? _cloneDeep(form)
				: {
					properties: {
						status: FormConst.WORKING,
						startDate: new Date(),
						endDate: new Date(),
						exportPDF: FormConst.EXPORT_PDF,
						recievers: new Array(0),
						recieversRegions: new Array(0),
						recieversTeacherBranches: new Array(0),
						recieversPerfectures: new Array(0),
						sendUserEmail: FormConst.DO_NOT_NOTIFY_USER_BY_EMAIL,
						sendAdminEmail: FormConst.DO_NOT_NOTIFY_ADMIN_BY_EMAIL,
						//tempSelectedPerfectures: new Array(0)
						//numberOfSubmissions: FormConst.SINGLE_SUBMISSION
					},
					display: 'form',
					type: 'form',
					components: [],
					access: [],
				},
			gsisInState: false,
			archive: false,
			cancel: false,
			recieversMain: [
				['ΠΔΕ ', 'ΠΕΡΙΦΕΡΕΙΑΚΗ ΔΙΕΥΘΥΝΣΗ ΕΚΠΑΙΔΕΥΣΗΣ', '0'],
				['ΔΠΕ ', 'ΔΙΕΥΘΥΝΣΗ ΠΡΩΤΟΒΑΘΜΙΑΣ ΕΚΠΑΙΔΕΥΣΗΣ', '1'],
				['ΔΔΕ ', 'ΔΙΕΥΘΥΝΣΗ ΔΕΥΤΕΡΟΒΑΘΜΙΑΣ ΕΚΠΑΙΔΕΥΣΗΣ', '2'],
				['ΓΑΚ ', 'ΓΕΝΙΚΑ ΑΡΧΕΙΑ', '3'],
				['Βιβλιοθήκες ', 'ΔΗΜΟΣΙΑ ΒΙΒΛΙΟΘΗΚΗ', '4'],
				['ΚΠΕ ', 'ΚΕΝΤΡΟ ΠΕΡΙΒΑΛΛΟΝΤΙΚΗΣ ΕΚΠΑΙΔΕΥΣΗΣ', '5'],
				['ΠΕΚΕΣ ', 'ΠΕ.Κ.Ε.Σ.', '6'],
				['ΚΕΣΥ ', 'ΚΕΣΥ', '7'],
				['ΚΕΑ ', 'ΚΕΑ', '8'],
				['ΕΚΦΕ ', 'ΕΚΦΕ', '9'],
			],
			recieversSchoolLevel: [['ΝΗΠΙΑΓΩΓΕΙΟ', 'ΕΚΠΑΙΔΕΥΤΙΚΗ ΜΟΝΑΔΑ ΝΗΠΙΑΓΩΓΕΙΟ', '10'], ['ΔΗΜΟΤΙΚΟ', 'ΕΚΠΑΙΔΕΥΤΙΚΗ ΜΟΝΑΔΑ ΔΗΜΟΤΙΚΟ', '11'], ['ΓΥΜΝΑΣΙΟ', 'ΕΚΠΑΙΔΕΥΤΙΚΗ ΜΟΝΑΔΑ ΓΥΜΝΑΣΙΟ', '12'], ['ΛΥΚΕΙΟ', 'ΕΚΠΑΙΔΕΥΤΙΚΗ ΜΟΝΑΔΑ ΛΥΚΕΙΟ', '13'], ['ΕΠΑΓΓΕΛΜΑΤΙΚΟ ΛΥΚΕΙΟ', 'ΕΚΠΑΙΔΕΥΤΙΚΗ ΜΟΝΑΔΑ ΕΠΑΓΓΕΛΜΑΤΙΚΟ ΛΥΚΕΙΟ', '14']],
			recieversTeacherLevel: [['ΝΗΠΙΑΓΩΓΕΙΟ', 'ΕΚΠΑΙΔΕΥΤΙΚΟΣ ΝΗΠΙΑΓΩΓΕΙΟ', '20'], ['ΔΗΜΟΤΙΚΟ', 'ΕΚΠΑΙΔΕΥΤΙΚΟΣ ΔΗΜΟΤΙΚΟ', '21'], ['ΓΥΜΝΑΣΙΟ', 'ΕΚΠΑΙΔΕΥΤΙΚΟΣ ΓΥΜΝΑΣΙΟ', '22'], ['ΛΥΚΕΙΟ', 'ΕΚΠΑΙΔΕΥΤΙΚΟΣ ΛΥΚΕΙΟ', '23'], ['ΕΠΑΓΓΕΛΜΑΤΙΚΟ ΛΥΚΕΙΟ', 'ΕΚΠΑΙΔΕΥΤΙΚΟΣ ΕΠΑΓΓΕΛΜΑΤΙΚΟ ΛΥΚΕΙΟ', '24']],
			recieversStudentLevel: [['ΝΗΠΙΑΓΩΓΕΙΟ', 'ΜΑΘΗΤΗΣ ΝΗΠΙΑΓΩΓΕΙΟ', '30'], ['ΔΗΜΟΤΙΚΟ', 'ΜΑΘΗΤΗΣ ΔΗΜΟΤΙΚΟ', '31'], ['ΓΥΜΝΑΣΙΟ', 'ΜΑΘΗΤΗΣ ΓΥΜΝΑΣΙΟ', '32'], ['ΛΥΚΕΙΟ', 'ΜΑΘΗΤΗΣ ΛΥΚΕΙΟ', '33'], ['ΕΠΑΓΓΕΛΜΑΤΙΚΟ ΛΥΚΕΙΟ', 'ΜΑΘΗΤΗΣ ΕΠΑΓΓΕΛΜΑΤΙΚΟ ΛΥΚΕΙΟ', '34']],
			recieversCheckedAll: false,
			casAllChecked: false,
			gsisChecked: false,
		};

		this.checkAll = this.checkAll.bind(this);
		this.setChecked = this.setChecked.bind(this);
		this.handleCheckCAS = this.handleCheckCAS.bind(this);
		this.handleCheckGSIS = this.handleCheckGSIS.bind(this);
		//this.handleRecieversChange2 = this.handleRecieversChange2.bind(this);
		this.handleRecieversChange = this.handleRecieversChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleArchiveForm = this.handleArchiveForm.bind(this);
		this.toggleArchive = this.toggleArchive.bind(this);
		this.toggleCancel = this.toggleCancel.bind(this);
		this.onRegionsChange = this.onRegionsChange.bind(this);
		this.onPerfecturesChange = this.onPerfecturesChange.bind(this);
		this.onTeacherBranchesChange = this.onTeacherBranchesChange.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
		//Add title to all inputs, WCAG complied
		$(":input").attr('title', 'Πεδίο εισαγωγής');
		/* $( 'input[type="search"]').each(function( index ) {
			console.log( index + ": " + $( this ).text() );
		  }); */
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	onRegionsChange(e) {

		//clear recieversPerfectures every time change regions
		let blank_array = [];
		const clear_perfecture = 'properties.recieversPerfectures';
		this.setState(prev => {
			const form = _cloneDeep(prev.form);
			_set(form, clear_perfecture, blank_array);
			return {
				...prev,
				form,
			};
		});

		const path = 'properties.recieversRegions';

		/* console.log("Regions:", e) */
		let tempRegions = []
		//clear array

		e.forEach(element => {
			/* console.log(element.label) */
			tempRegions.push(element)
			// push perfectures of this region in state variable tempSelectedPerfecture

		})


		this.setState(prev => {
			const form = _cloneDeep(prev.form);
			_set(form, path, tempRegions);
			return {
				...prev,
				form,
			};
		});


		//this.handlePerfectureOptions

		const path_options = 'properties.tempSelectedPerfectures';

		//this.setState ({ tempSelectedPerfectures : []})
		//this.setState({recieversPerfectures :[]})

		let perfecturesList = []


		e.forEach(element => {
			//console.log(typeof element, element)
			element.perfectures.forEach(el => {
				perfecturesList.push(el)
			})
		})


		this.setState(prev => {
			const form = _cloneDeep(prev.form);
			_set(form, path_options, perfecturesList);
			return {
				...prev,
				form,
			};
		});
	}

	onPerfecturesChange(e) {

		const path = 'properties.recieversPerfectures';

		/* console.log("Regions:", e) */
		let tempPerfectures = []
		e.forEach(element => {
			/* console.log(element.label) */
			tempPerfectures.push(element)
		})
		/* console.log("tempRegions:", tempRegions) */

		this.setState(prev => {
			const form = _cloneDeep(prev.form);
			_set(form, path, tempPerfectures);
			return {
				...prev,
				form,
			};
		});
		/* console.log("FORM PROPERTIES", this.state.form.properties)
		console.log("form recieversRegions", this.state.form.properties.recieversPerfectures) */
	}

	onTeacherBranchesChange(e) {

		const path = 'properties.recieversTeacherBranches';

		//console.log("TeacherBranches:", e)
		let tempTeacherBranches = []
		e.forEach(element => {
			console.log(element.label)
			tempTeacherBranches.push(element)
		})
		//console.log("tempTeacherBranches:", tempTeacherBranches)

		this.setState(prev => {
			const form = _cloneDeep(prev.form);
			_set(form, path, tempTeacherBranches);
			return {
				...prev,
				form,
			};
		});
		//console.log("FORM PROPERTIES", this.state.form.properties)
		//console.log("form tempTeacherBranches", this.state.form.properties.recieversTeacherBranches)
	}

	checkAll(e) {
		e.preventDefault();
		if (this.state.recieversCheckedAll === false) {
			this.state.recieversMain.forEach(r => {
				this.handleRecieversChange(r, true);
			});
			this.state.recieversSchoolLevel.forEach(r => {
				this.handleRecieversChange('ΕΚΠΑΙΔΕΥΤΙΚΟΣ ' + r, true);
			});
			this.state.recieversSchoolLevel.forEach(r => {
				this.handleRecieversChange('ΜΑΘΗΤΗΣ ' + r, true);
			});
		} else {
			this.state.recieversMain.forEach(r => {
				this.handleRecieversChange(r, false);
			});
			this.state.recieversSchoolLevel.forEach(r => {
				this.handleRecieversChange('ΕΚΠΑΙΔΕΥΤΙΚΟΣ ' + r, false);
			});
			this.state.recieversSchoolLevel.forEach(r => {
				this.handleRecieversChange('ΜΑΘΗΤΗΣ ' + r, false);
			});
		}

		this.setState(prevState => {
			return {
				...prevState,
				recieversCheckedAll: !prevState.recieversCheckedAll,
			};
		});
		//console.log(this.state)
	}

	setChecked(recieverName) {
		/* console.log("SET CHECKED", this.state.form.properties)
		console.log("SET CHECKED", this.state.form.properties.recievers)
		console.log("SET CHECKED RECIEVER NAME", recieverName) */
		if (this.state.form.properties.recievers) {
			//console.log("TYPE oF", typeof this.state.form.properties.recievers)
			if (this.state.form.properties.recievers.includes('ΠΣΔ')) {
				this.setState(prev => {
					return {
						...prev,
						casAllChecked: true,
					};
				});
			}
			if (this.state.form.properties.recievers.includes('ΓΓΠΣ')) {
				this.setState(prev => {
					return {
						...prev,
						gsisChecked: true,
					};
				});
			}
			if (this.state.form.properties.recievers.includes(recieverName)) return true;
		}
		return false;
	}

	handleCheckGSIS(eventValue, eventChecked) {
		if (eventChecked === true) {
			const path = 'properties.recievers';
			let newRecieversArray = [];
			newRecieversArray = [eventValue];

			this.setState(prev => {
				const form = _cloneDeep(prev.form);
				_set(form, path, newRecieversArray);
				return {
					...prev,
					form,
					gsisChecked: true,
					//casAllChecked: false
				};
			});
		}

		if (eventChecked === false) {
			/* console.log(this.state.casAllChecked, " ", this.state.form.properties.recievers) */
			const path = 'properties.recievers';
			let newRecieversArray = [];

			this.setState(prev => {
				const form = _cloneDeep(prev.form);
				_set(form, path, newRecieversArray);
				/* console.log("PREV->", prev)
				if (prev.form.properties.recievers.includes('ΓΓΠΣ')) {
					console.log("YEAH")
					this.gsisInState = true
				} */
				return {
					...prev,
					form,
					gsisChecked: false,
					gsisInState: true,
					//casAllChecked: false
				};
			});
		}
		/* console.log(this.state.casAllChecked, " ", this.state.form.properties.recievers) */
	}

	handleCheckCAS(eventValue, eventChecked) {
		if (eventChecked === true) {
			const path = 'properties.recievers';
			let newRecieversArray = [];
			if (!this.state.form.properties.recievers) {
				newRecieversArray.push(eventValue);
			} else if (this.state.form.properties.recievers.includes('ΓΓΠΣ')) {
				newRecieversArray = [eventValue];
			} else {
				newRecieversArray = [eventValue];
			}

			this.setState(prev => {
				const form = _cloneDeep(prev.form);
				_set(form, path, newRecieversArray);
				return {
					...prev,
					form,
					casAllChecked: true,
					gsisChecked: false,
				};
			});
		}

		if (eventChecked === false) {
			/* console.log(this.state.casAllChecked, " ", this.state.form.properties.recievers) */
			const path = 'properties.recievers';
			let newRecieversArray = [];
			if (this.state.form.properties.recievers.includes('ΓΓΠΣ')) {
				newRecieversArray = ['ΓΓΠΣ'];
			}
			this.setState(prev => {
				const form = _cloneDeep(prev.form);
				_set(form, path, newRecieversArray);
				return {
					...prev,
					form,
					casAllChecked: false,
					gsisChecked: false,
				};
			});
		}
		/* console.log(this.state.casAllChecked, " ", this.state.form.properties.recievers) */
	}

	handleRecieversChange(eventValue, eventChecked) {
		/* console.log(this.state.form.properties)
		console.log(eventValue, eventChecked) */

		const path = 'properties.recievers';

		let arrayRecievers;
		if (this.state.form.properties.recievers) {
			arrayRecievers = this.state.form.properties.recievers;
		} else {
			arrayRecievers = [];
		}

		// to use splice to delete element if uncheked
		//**********************
		//console.log("arrayRecievers", arrayRecievers)
		if (eventChecked === false) {
			if (arrayRecievers.includes(eventValue)) {
				//console.log("IN")
				let i = 0;
				while (i < arrayRecievers.length) {
					if (arrayRecievers[i] === eventValue) {
						arrayRecievers.splice(i, 1);
					} else {
						++i;
					}
				}
			}
		} else {
			arrayRecievers.push(eventValue);
		}

		//console.log("typeof path", typeof path, "path:", path)

		this.setState(prev => {
			const form = _cloneDeep(prev.form);
			_set(form, path, arrayRecievers);
			return {
				...prev,
				form,
			};
		});
		/* console.log("FORM PROPERTIES", this.state.form.properties)
		console.log("form recievers", this.state.form.properties.recievers) */
	}

	handleSelectChange(selectedOptions) {
		const roles = selectedOptions.map(role => role.value);
		const data = [{ roles, type: 'read_all' }];
		const path = 'access';
		this.setState(prev => {
			const form = _cloneDeep(prev.form);
			_set(form, path, data);
			return {
				...prev,
				form,
			};
		});
		/* this.setState(state => {
			return {
				selectedRoles: selectedOptions
			};
		}); */
	}

	handleDateChange(path, date) {
		//console.log("Date handle change", " path:", path, "date:", date)
		let updatedDate
		if (path.includes("startDate")) {
			updatedDate = removeTimeFromDate(date)
		} else if (path.includes("endDate")) {
			updatedDate = set2359Hours(date)
		}
		this.setState(prev => {
			const form = _cloneDeep(prev.form);
			_set(form, path, updatedDate);
			return {
				...prev,
				form,
			};
		});
	}

	handleChange(path, event) {
		/* console.log("path", path, " event", event); */
		const { target } = event;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		this.setState(prev => {
			const form = _cloneDeep(prev.form);
			_set(form, path, value);

			// If setting title, autogenerate name and path as well.
			if (path === 'title' && !form._id) {
				form.name = _camelCase(value);
				form.path = _camelCase(value).toLowerCase();
			}

			return {
				...prev,
				form,
			};
		});
	}

	handleChangeStatusWorkingToPublished(path, event) {
		const { target } = event;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		this.setState(
			prev => {
				const form = _cloneDeep(prev.form);
				_set(form, path, value);

				// If setting title, autogenerate name and path as well.
				if (path === 'title' && !form._id) {
					form.name = _camelCase(value);
					form.path = _camelCase(value).toLowerCase();
				}

				return {
					...prev,
					form,
				};
			},
			/* () => {
			//SAVE THE FORM
			this.saveForm()
		} */
		);
	}

	handleArchiveForm(e) {
		e.preventDefault();

		this.setState(
			prev => {
				const form = _cloneDeep(prev.form);
				// CHANGE THE SUBMISSIONACCESS
				form.submissionAccess.forEach(item => {
					if (!(item.type === 'read_own')) {
						item.roles = [];
					}
				});
				// CHANGE THE FORM STATUS TO ARCHIVE
				form.properties.status = FormConst.ARCHIVED;
				return {
					...prev,
					form,
				};
			},
			() => {
				//SAVE THE FORM
				this.saveForm();
			},
			this.toggleArchive(),
		);
	}

	toggleArchive() {
		this.setState(state => {
			return {
				archive: !this.state.archive,
			};
		});
	}

	toggleCancel() {
		this.setState(state => {
			return {
				cancel: !this.state.cancel,
			};
		});
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		// Only update if key form info has changed. The builder handles form component changes itself.
		// console.log(this.state.form.access);
		// console.log(nextState.form.access);

		return (
			this.state.form.title !== nextState.form.title ||
			this.state.form.name !== nextState.form.name ||
			this.state.form.path !== nextState.form.path ||
			this.state.form.display !== nextState.form.display ||
			this.state.form.type !== nextState.form.type ||
			this.state.form.components !== nextState.form.components ||
			this.state.form.properties.startDate !== nextState.form.properties.startDate ||
			this.state.form.properties.endDate !== nextState.form.properties.endDate ||
			this.state.form.properties.status !== nextState.form.properties.status ||
			this.state.form.properties.exportPDF !== nextState.form.properties.exportPDF ||
			//this.state.form.properties.recievers !== nextState.form.properties.recievers ||
			this.state.form.properties.numberOfSubmissions !== nextState.form.properties.numberOfSubmissions ||
			this.state.form.properties.sendUserEmail !== nextState.form.properties.sendUserEmail ||
			this.state.form.properties.sendAdminEmail !== nextState.form.properties.sendAdminEmail ||
			// this.state.form.access !== nextState.form.access ||
			this.state.selectedRoles !== nextState.selectedRoles ||
			this.state.archive !== nextState.archive ||
			this.state.cancel !== nextState.cancel ||
			this.state.recieversCheckedAll !== nextState.recieversCheckedAll ||
			this.state.casAllChecked !== nextState.casAllChecked ||
			this.state.gsisChecked !== nextState.gsisChecked
			// (this.state.form.access!==undefined &&(this.state.form.access[0].roles!==nextState.form.access[0].roles))
		);
	}

	render() {
		const { form } = this.state;
		/* console.log("FORM ===> ", form) */
		const formSelectedRoles = form.access;
		const { saveText, roles, flagToHidePublishOptionFromCreate } = this.props;
		let { gsis } = this.props;
		/* console.log("gsis -->", gsis) */
		if (gsis === undefined) {
			if (form.properties.recievers.includes('ΓΓΠΣ')) {
				gsis = true;
			}
			/* else {
				gsis = false
			} */
		}
		/* console.log("gsis when undefined -->", gsis) */
		const rolesArr = Object.entries(roles);
		//console.log("STATE IN RENDER", this.state)

		let selectedRoles;
		if (formSelectedRoles !== undefined) {
			selectedRoles = rolesArr
				.filter(role => {
					// eslint-disable-next-line
					const [name, value] = role;
					const res = formSelectedRoles[0].roles.filter(formRole => {
						if (formRole === value._id) return true;
						return false;
					});
					return res.length;
				})
				.map(getRoleOptions);
		} else {
			// selectedRoles = rolesArr.map(getRoleOptions);
			selectedRoles = null;
		}

		// ** START ** Disable Publish Option in Create Form
		let disablePublishOption;
		if (flagToHidePublishOptionFromCreate === true) {
			disablePublishOption = true;
		} else {
			disablePublishOption = '';
		}
		// ** END ** Disable Publish Option in Create Form

		// ** START ** Disable select when a form is Archived
		let disableSelect = form.properties.status === FormConst.ARCHIVED ? 'disabled' : false;
		//console.log("disableSelect", disableSelect)
		// ** END ** Disable select when a form is Archived

		let disableArchiveBtn = form.properties.status === FormConst.ARCHIVED ? true : false;

		//** START ** Check dates for Archive Option Availiability
		const currentDate = new Date();
		var formEndDate = new Date(form.properties.endDate);
		//var validFormEndDate = formEndDate instanceof Date && !isNaN(formEndDate)

		//
		var archiveSelectOptionDisabled;
		if (formEndDate) {
			archiveSelectOptionDisabled = currentDate < formEndDate;
		} else {
			archiveSelectOptionDisabled = true;
		}
		//
		// ** END ** Check dates for Archive Option Availiability

		// ** START ** Archive Button
		let archiveButton;
		if (!archiveSelectOptionDisabled) {
			archiveButton = (
				<>
					<Button color="ghost-danger" disabled={disableArchiveBtn} onClick={this.toggleArchive} className="mr-1">
						Αρχειοθέτηση
					</Button>
					<Modal isOpen={this.state.archive} toggle={this.toggleArchive} className={'modal-danger ' + this.props.className}>
						<ModalHeader toggle={this.toggleArchive}>Αρχειοθέτηση</ModalHeader>
						<ModalBody>Επιλέξατε να αρχειοθετήσετε τη φόρμα. Οι χρήστες δεν θα μπορούν πλέον να κάνουν νέες υποβολές ή να τροποποιούν παλιότερες. Θέλετε να συνεχίσετε;</ModalBody>
						<ModalFooter>
							<Button
								color="danger"
								onClick={e => {
									this.handleArchiveForm(e);
								}}
							>
								Ναι, να γίνει η αρχειοθέτηση
							</Button>{' '}
							<Button color="secondary" onClick={this.toggleArchive}>
								Ακύρωση
							</Button>
						</ModalFooter>
					</Modal>
				</>
			);
		} else {
			archiveButton = '';
		}
		// ** END ** Archive Button

		let cancelButton;
		if (disableArchiveBtn) {
			cancelButton = (
				<>
					<Button
						color="secondary"
						onClick={e => {
							window.history.back();
						}}
					>
						Ακύρωση
					</Button>
				</>
			);
		} else {
			cancelButton = (
				<>
					<Button onClick={this.toggleCancel} className="mr-1">
						Ακύρωση
					</Button>
					<Modal isOpen={this.state.cancel} toggle={this.toggleCancel} className={'modal-danger' + this.props.className}>
						<ModalHeader toggle={this.toggleCancel}>Ακύρωση</ModalHeader>
						<ModalBody>Aλλαγές που έχετε πραγματοποιήσει, δε θα αποθηκευτούν. Θέλετε να συνεχίσετε;</ModalBody>
						<ModalFooter>
							<Button
								color="danger"
								onClick={e => {
									window.history.back();
								}}
							>
								Δεν επιθυμώ αποθήκευση
							</Button>{' '}
							<Button color="secondary" onClick={this.toggleCancel}>
								Ακύρωση
							</Button>
						</ModalFooter>
					</Modal>
				</>
			);
		}

		// ** START ** Disable Working status Option when a Form is published
		let disableEditStatusOption;
		if (form.properties.status === FormConst.PUBLISHED) {
			disableEditStatusOption = true;
		}
		// ** END ** Disable Working status Option when a Form is published

		const rolesOptions = rolesArr.map(role => {
			const [name, value] = role;
			return { value: value._id, label: name };
		});

		// ** START ** Disable Form Builder when the Form is Published
		// const disableFormBuilder = (form.properties.status === "published") ? true : false
		// ** END ** Disable Form Builder when the Form is Published

		//'Save Form Button' Style depending on form.properties.status
		const saveFormButtonStyle = form.properties.status === FormConst.ARCHIVED ? 'btn btn-warning' : 'btn btn-primary';

		return (
			<form>
				<div className="row">
					<div className="col-md-12">
						<div className="form-row">
							<div className="col-lg-2 col-md-4 col-sm-4">
								<div id="form-group-title" className="form-group">
									<label htmlFor="title" className="control-label field-required">
										Τίτλος
									</label>
									<input type="text" className="form-control" id="title" placeholder="Enter the form title" value={form.title || ''} onChange={event => this.handleChange('title', event)} />
								</div>
							</div>
							<div className="col-lg-2 col-md-4 col-sm-4">
								<div id="form-group-name" className="form-group">
									<label htmlFor="name" className="control-label field-required">
										Όνομα
									</label>
									<input type="text" className="form-control" id="name" placeholder="Enter the form machine name" value={form.name || ''} onChange={event => this.handleChange('name', event)} />
								</div>
							</div>
							<div className="col-lg-2 col-md-4 col-sm-4">
								<div id="form-group-display" className="form-group">
									<label htmlFor="form-display" className="control-label">
										Προβολή ως
									</label>
									<div className="input-group">
										<select className="form-control" name="form-display" id="form-display" value={form.display || ''} onChange={event => this.handleChange('display', event)}>
											<option label="Form" value="form">
												Φόρμα
											</option>
											<option label="Wizard" value="wizard">
												Οδηγός
											</option>
										</select>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-md-4 col-sm-4">
								<div id="form-group-type" className="form-group">
									<label htmlFor="form-type" className="control-label">
										Τύπος
									</label>
									<div className="input-group">
										<select className="form-control" name="form-type" id="form-type" value={this.state.form.type} onChange={event => this.handleChange('type', event)}>
											<option label="Form" value="form">
												Φόρμα
											</option>
											<option label="Resource" value="resource">
												Resource
											</option>
										</select>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-md-4 col-sm-4">
								<div id="form-group-path" className="form-group">
									<label htmlFor="path" className="control-label field-required">
										Σύνδεσμος Url
									</label>
									<div className="input-group">
										<input type="text" className="form-control" id="path" placeholder="example" style={{ textTransform: 'lowercase', width: '120px' }} value={form.path || ''} onChange={event => this.handleChange('path', event)} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="form-row">
							<div className="col-lg-2 col-md-4 col-sm-4">
								<div id="form-group-status" className="form-group">
									<label htmlFor="form-status" className="control-label">
										Κατάσταση φόρμας
									</label>
									<select className="form-control" id="form-status" name="form-status" value={this.state.form.properties.status} onChange={event => this.handleChangeStatusWorkingToPublished('properties.status', event)} disabled={disableSelect}>
										<option label="Υπο επεξεργασία" value={FormConst.WORKING} disabled={disableEditStatusOption}>
											Υπο επεξεργασία
										</option>
										<option label="Δημοσιευμένη" value={FormConst.PUBLISHED} disabled={disablePublishOption}>
											Δημοσιευμένη
										</option>
										{/* <option label="Αρχειοθετημένη" value="archived" disabled={archiveSelectOptionDisabled}>
											Αρχειοθετημένη
										</option>
 										*/}
									</select>
								</div>
							</div>

							<div className="col-lg-2 col-md-4 col-sm-4">
								<div id="form-group-date" className="form-group">
									<label htmlFor="form-date-start" className="control-label">
										Έναρξη
									</label>
									<DatePicker
										id='form-date-start'
										selected={Date.parse(this.state.form.properties.startDate)}
										value={Date.parse(this.state.form.properties.startDate)}
										onChange={date => this.handleDateChange('properties.startDate', date)}
										locale="el"
										selectsStart
										disabled={disableSelect}
										startDate={this.state.form.properties.startDate}
										endDate={this.state.form.properties.endDate}
										// showTimeSelect
										// timeFormat="HH:mm"
										// timeIntervals={15}
										// timeCaption="time"
										dateFormat="dd-MM-yyyy"
										className="form-control"
									//defaultValue = {currentDate}
									//disabled = {!archiveSelectOptionDisabled}
									/>
								</div>
							</div>
							<div className="col-lg-2 col-md-4 col-sm-4">
								<div id="form-group-date" className="form-group">
									<label htmlFor="form-date-end" className="control-label">
										Λήξη
									</label>
									<DatePicker
										id='form-date-end'
										selected={Date.parse(this.state.form.properties.endDate)}
										onChange={date => this.handleDateChange('properties.endDate', date)}
										locale="el"
										selectsEnd
										disabled={disableSelect}
										startDate={form.properties.startDate}
										endDate={this.state.form.properties.endDate}
										minDate={this.state.form.properties.startDate}
										// showTimeSelect
										// timeFormat="HH:mm"
										// timeIntervals={15}
										// timeCaption="time"
										dateFormat="dd-MM-yyyy"
										className="form-control"
									//defaultValue = {currentDate}
									//disabled = {!archiveSelectOptionDisabled}
									/>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-4">
								<div id="form-group-roles" className="form-group">
									<label htmlFor="form-roles" className="control-label">
										Αποδέκτες
									</label>
									<Select id='form-roles' name="form-roles" isMulti placeholder="Αποδέκτες" value={selectedRoles} options={rolesOptions} onChange={this.handleSelectChange} />
								</div>
							</div>
						</div>
						{/* <div style={{clear:'both'}}>
								<p>{archiveButton}</p>
						</div> */}
					</div>
					{/* <div className="col">
						<div id="save-buttons" className="pull-right">
							<div className="form-group pull-right">
								<span className={saveFormButtonStyle} onClick={() => {
									this.saveForm()
								}
								}>
									{saveText}
								</span>
							</div>
						</div>
					</div> */}
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="form-row">
							<div className="col-lg-2 col-md-4 col-sm-4">
								<div id="form-group-exportpdf" className="form-group">
									<label htmlFor="form-exportpdf" className="control-label">
										Εξαγωγή αποδεικτικού υποβολής .pdf
									</label>
									<div className="input-group">
										<select className="form-control" name="form-exportpdf" id="form-exportpdf" value={this.state.form.properties.exportPDF} onChange={event => this.handleChange('properties.exportPDF', event)}>
											<option label="Ναί" value={FormConst.EXPORT_PDF}>
												Ναί
											</option>
											<option label="Όχι" value={FormConst.DONT_EXPORT_PDF}>
												Όχι
											</option>
										</select>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-md-4 col-sm-4">
								<div id="form-group-numberofsubmissions" className="form-group">
									<label htmlFor="form-numberofsubmissions" className="control-label">
										Αριθμός Υποβολών:
									</label>
									<div className="input-group">
										<select className="form-control" name="form-numberofsubmissions" id="form-numberofsubmissions" value={this.state.form.properties.numberOfSubmissions} onChange={event => this.handleChange('properties.numberOfSubmissions', event)}>
											<option label="Μοναδική" value={FormConst.SINGLE_SUBMISSION}>
												{FormConst.SINGLE_SUBMISSION}
											</option>
											<option label="Πολλαπλές" value={FormConst.MULTIPLE_SUBMISSIONS}>
												{FormConst.MULTIPLE_SUBMISSIONS}
											</option>
										</select>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-md-4 col-sm-4">
								<div id="form-group-sendUserEmail" className="form-group">
									<label htmlFor="form-sendUserEmail" className="control-label">
										Ενημέρωση χρήστη με email στην τελική υποβολή:
									</label>
									<div className="input-group">
										<select className="form-control" name="form-sendUserEmail" id="form-sendUserEmail" value={this.state.form.properties.sendUserEmail} onChange={event => this.handleChange('properties.sendUserEmail', event)}>
											<option label="Όχι" value={FormConst.DO_NOT_NOTIFY_USER_BY_EMAIL}>
												Όχι
											</option>
											<option label="Ναι" value={FormConst.NOTIFY_USER_BY_EMAIL}>
												Ναί
											</option>
										</select>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-md-4 col-sm-4">
								<div id="form-group-sendAdminEmail" className="form-group">
									<label htmlFor="form-sendAdminEmail" className="control-label">
										Ενημέρωση Διαχειριστή με email στην τελική υποβολή χρήστη:
									</label>
									<div className="input-group">
										<select className="form-control" name="form-sendAdminEmail" id="form-sendAdminEmail" value={this.state.form.properties.sendAdminEmail} onChange={event => this.handleChange('properties.sendAdminEmail', event)}>
											<option label="Όχι" value={FormConst.DO_NOT_NOTIFY_ADMIN_BY_EMAIL}>
												Όχι
											</option>
											<option label="Ναι" value={FormConst.NOTIFY_ADMIN_BY_EMAIL}>
												Ναί
											</option>
										</select>
									</div>
								</div>
							</div>
							{/* {gsis || this.state.gsisInState ? ( */}
							<div className="col-lg-2 col-md-4 col-sm-4">
								<div id="form-group-pin" className="form-group">
									<label htmlFor="form-pin" className="control-label">
										<strong>PIN</strong>
									</label>
									<input type="text" className="form-control"
										style={{width:"55pt"}}
										id="pin" placeholder="123456"
										minlength="6"
										maxlength="6"
										/* pattern="\[0-9\]{1-4}" */
										size="6"
										value={form.properties.pin || ''}
										onChange={event =>
											this.handleChange('properties.pin', event)} />
								</div>
							</div>
							{/* ) : (
								<div></div>
							)} */}

						</div>
					</div>
				</div>

				{/* start  code*/}
				<div className="row">
					<div id="form-group-recievers" className="form-group">
						<label htmlFor="form-reciever" className="reciever-label"><h5>Αποδέκτες</h5></label>
					</div>
				</div>

				<div className="newrow">
					{gsis || this.state.gsisInState ? (
						<div>
							<p>
								ΓΓΠΣ {'\u00A0'}
								<input type="checkbox" name="ΓΓΠΣ" value="ΓΓΠΣ" checked={this.setChecked('ΓΓΠΣ')} onChange={event => this.handleCheckGSIS(event.target.value, event.target.checked)} />
								{'\u00A0'} {/* <span style={{ color: "#dc3545" }} >- Η επιλογή αποδεκτών ΓΓΠΣ <span style={{ fontWeight: "bold" }}>αποκλείει</span> την ταυτόχρονη επιλογή απδεκτών ΠΣΔ. </span> */}
							</p>
						</div>
					) : (
						<div style={{ display: !this.state.gsisChecked ? 'block' : 'none' }}>
							<p>
								Πανελλήνιο Σχολικό Δίκτυο {'\u00A0'}
								<input type="checkbox" name="ΠΣΔ" value="ΠΣΔ" checked={this.setChecked('ΠΣΔ')} onChange={event => this.handleCheckCAS(event.target.value, event.target.checked)} />
							</p>
							<div /* className="row col-md-12" */>
								<div style={{ display: !this.state.casAllChecked ? 'block' : 'none' }}>
									<div>
										<ListGroup horizontal>
											<ReactMultiSelectCheckboxes
												style={{ border: '10px solid #4CAF50', padding: '70px' }}
												options={options_regions}
												placeholderButtonLabel="ΟΛΕΣ ΟΙ ΠΕΡΙΦΕΡΕΙΕΣ"
												defaultValue={this.state.form.properties.recieversRegions}
												onChange={this.onRegionsChange}
											/>



											<ReactMultiSelectCheckboxes
												style={{ border: '10px solid #4CAF50', padding: '70px' }}
												options={this.state.form.properties.tempSelectedPerfectures}



												placeholderButtonLabel="ΟΛΕΣ ΟΙ ΠΕΡΙΟΧΕΣ"
												defaultValue={this.state.form.properties.recieversPerfectures}
												onChange={this.onPerfecturesChange}
											/>
										</ListGroup>
									</div>
									<Card>
										<div className="support-units">
											<ListGroup horizontal>
												{this.state.recieversMain.map((r, key) => (
													<ListGroupItem key={r[0]}>
														{r[0]}
														<input type="checkbox" name={r[1]} value={r[2]} checked={this.setChecked(r[2])} onChange={event => this.handleRecieversChange(event.target.value, event.target.checked)} />
													</ListGroupItem>
												))}
											</ListGroup>
										</div>


										<div className="school-units">
											<div><b>ΕΚΠΑΙΔΕΥΤΙΚΗ ΜΟΝΑΔΑ</b></div>
											<ListGroup horizontal>
												{this.state.recieversSchoolLevel.map((r, key) => (
													<ListGroupItem key={r[0]}>
														{r[0]}
														<input type="checkbox" name={r[1]} value={r[2]} checked={this.setChecked(r[2])} onChange={event => this.handleRecieversChange(event.target.value, event.target.checked)} />
													</ListGroupItem>
												))}
											</ListGroup>
										</div>


										<div className="school-teachers">
											<div><b>ΕΚΠΑΙΔΕΥΤΙΚΟΣ</b></div>
											<div>
												<ReactMultiSelectCheckboxes
													options={options_specialties}
													placeholderButtonLabel="ΟΛΕΣ ΟΙ ΕΙΔΙΚΟΤΗΤΕΣ"
													defaultValue={this.state.form.properties.recieversTeacherBranches}
													onChange={this.onTeacherBranchesChange}
												/>

											</div>

											<ListGroup horizontal>
												{this.state.recieversTeacherLevel.map((r, key) => (
													<ListGroupItem key={r[0]}>
														{r[0]}
														<input type="checkbox" name={r[1]} value={r[2]} checked={this.setChecked(r[2])} onChange={event => this.handleRecieversChange(event.target.value, event.target.checked)} />
													</ListGroupItem>
												))}
											</ListGroup>

										</div>

										<div className="school-students">
											<div><b>ΜΑΘΗΤΗΣ</b></div>
											<ListGroup horizontal>
												{this.state.recieversStudentLevel.map((r, key) => (
													<ListGroupItem key={r[0]}>
														{r[0]}
														<input type="checkbox" name={r[1]} value={r[2]} checked={this.setChecked(r[2])} onChange={event => this.handleRecieversChange(event.target.value, event.target.checked)} />
													</ListGroupItem>
												))}
											</ListGroup>

										</div>
									</Card>

								</div>
							</div>
							{/* start LOOP for REGIONS */}
						</div>
					)}
				</div>
				{/* end  code*/}

				<div className="row">
					<div className="col">
						<div className="form-row"></div>
						<div className="pull-right" style={{ marginLeft: '5px' }}>
							{cancelButton}
						</div>
						<div id="save-buttons" className="pull-right">
							<div className="form-group pull-right">
								<span
									className={saveFormButtonStyle}
									onClick={() => {
										if (!form.properties.recievers || form.properties.recievers.length === 0) {
											toast.error('Πρέπει να επιλέξετε τουλάχιστον έναν Αποδέκτη', {
												position: 'top-right',
												autoClose: 4000,
												hideProgressBar: true,
												closeOnClick: true,
												pauseOnHover: false,
												draggable: true,
												progress: undefined,
											});
										}
										else if (form.properties.gsis && form.properties.gsis === true && !userEmailContactExists(form)) {
											toast.error('Στα πεδία της φόρμας πρέπει να υπάρχει πεδίο Ηλεκτρονικού Ταχυδρομείου με API name userEmailContact', {
												position: 'top-right',
												autoClose: 4000,
												hideProgressBar: true,
												closeOnClick: true,
												pauseOnHover: false,
												draggable: true,
												progress: undefined,
											});
										}
										else if (form.properties.gsis && form.properties.gsis === true && !userEmailContactExists(form)) {
											toast.error('Στα πεδία της φόρμας πρέπει να υπάρχει πεδίο Ηλεκτρονικού Ταχυδρομείου με API name userEmailContact', {
												position: 'top-right',
												autoClose: 4000,
												hideProgressBar: true,
												closeOnClick: true,
												pauseOnHover: false,
												draggable: true,
												progress: undefined,
											});
										}
										else if (!userEmailExists(form)) {
											toast.error('Στα πεδία της φόρμας πρέπει να υπάρχει πεδίο Ηλεκτρονικού Ταχυδρομείου ή Κειμένου με API name userEmail', {
												position: 'top-right',
												autoClose: 4000,
												hideProgressBar: true,
												closeOnClick: true,
												pauseOnHover: false,
												draggable: true,
												progress: undefined,
											});
										}
										else if (!userNameExists(form)) {
											toast.error('Στα πεδία της φόρμας πρέπει να υπάρχει πεδίο Κειμένου με API name "name"', {
												position: 'top-right',
												autoClose: 4000,
												hideProgressBar: true,
												closeOnClick: true,
												pauseOnHover: false,
												draggable: true,
												progress: undefined,
											})
										}
										else if (form.properties.pin && form.properties.pin !== undefined &&
											((/[a-zA-ZΑ-Ωα-ωίϊΐόάέύϋΰήώ]/g).test(form.properties.pin) || form.properties.pin.length < 6
												|| form.properties.pin.length > 6)) {
											toast.error('Το PIN πρέπει να περιέχει 6 αριθμητικά ψηφία', {
												position: 'top-right',
												autoClose: 4000,
												hideProgressBar: true,
												closeOnClick: true,
												pauseOnHover: false,
												draggable: true,
												progress: undefined,
											})
										}
										else {
											addDefaultCSSToSelectBoxesAndRadios(form)
											addDefaultCSSToFileFields(form)
											this.saveForm();
										}
									}}
								>
									{saveText}
								</span>
							</div>
						</div>
						<div className="pull-right">{archiveButton}</div>
					</div>
				</div>

				<FormBuilder key={form._id} form={form} options={this.props.options} builder={this.props.builder} onChange={this.formChange} />
			</form>
		);
	}
}

YpepthFormEdit.propTypes = {
	form: PropTypes.object,
	options: PropTypes.object,
	builder: PropTypes.object,
	onSave: PropTypes.func,
	roles: PropTypes.object,
	flagToHidePublishOptionFromCreate: PropTypes.bool,
};
