import React from "react";
import { useState, /* useEffect */ } from "react";
import { Link } from 'react-router-dom';
import { FormConst } from '../config';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';



const NewFormGridUsers = (props) => {

    
    const [keytab, setKeytab] = useState(FormConst.FORM_CATEGORY_GENIKES);

   

    
    const groupedData = (forms) => forms.reduce((accumulator, item) => {
        if (!item.properties.category) {
            item.properties.category = FormConst.FORM_CATEGORY_GENIKES
        }
        if (!accumulator[item.properties.category]) {
            accumulator[item.properties.category] = [];
        }
        accumulator[item.properties.category].push(item);
        return accumulator;
    }, {});

    const tabscategories = [FormConst.FORM_CATEGORY_GENIKES, FormConst.FORM_CATEGORY_DIAGONISMWN_YPAITHA, FormConst.FORM_CATEGORY_DIOIKITIKWN, FormConst.FORM_CATEGORY_ITHAGENEIAS, FormConst.FORM_CATEGORY_THRISKEYMATWN]
    

    let groupedForms = groupedData(props.forms.forms)
    //console.log("groupedForms", groupedForms)

    // Display the list of forms in each category , tabscategory is the parameter. 
    const Displaytabforms = ({ tabscategory}) => {
        return  <ul className="list-group">   
        {(groupedForms[tabscategory]?.length === 0 || !groupedForms[tabscategory]) 
          ? <p>Δεν υπάρχουν διαθέσιμες Φόρμες στην κατηγορία {tabscategory}</p> 
         : groupedForms[tabscategory]?.map(item => (
        <li key={item._id} className="list-group-item">
        <Link to={`/form/${item._id}/userSubmissions`}
        className="d-flex justify-content-between align-items-center"
        style={{ textDecoration: "none", fontWeight: "bold" }}>
        <span style={{ marginLeft: "5px" }}>{item.title}</span>
        <span title="Υποβολή" role="button" tabIndex="0" className="btn btn-primary btn-sm">
        <span>
        <i className="fa fa-pencil"></i>&nbsp;
        </span>
        </span>
        </Link>
        </li>
        ))}
        </ul>   


    }

    return (
        
        <Tabs
            id="category-tab"
            activeKey={keytab}
            onSelect={(k) => setKeytab(k)}
            className="category-tab"
            >
            
            <Tab eventKey={tabscategories[0]} title={tabscategories[0]}>

            <Displaytabforms tabscategory={tabscategories[0]} />
            
            </Tab>

           

            <Tab eventKey={tabscategories[1]} title={tabscategories[1]}>

            <Displaytabforms tabscategory={tabscategories[1]} />

            </Tab>
            
            <Tab eventKey={tabscategories[2]} title={tabscategories[2]}>

            <Displaytabforms tabscategory={tabscategories[2]} />

            </Tab>

            <Tab eventKey={tabscategories[3]} title={tabscategories[3]}>

            <Displaytabforms tabscategory={tabscategories[3]} />

            </Tab>


            <Tab eventKey={tabscategories[4]} title={tabscategories[4]}>

            <Displaytabforms tabscategory={tabscategories[4]} />

            </Tab>


            <Tab eventKey={"ΟΛΕΣ"} title={"ΟΛΕΣ"}>

            <ul className="list-group">
                                    
                                    {props.forms.forms.map(item => (
                                        <li key={item._id} className="list-group-item">
                                            <Link to={`/form/${item._id}/userSubmissions`}
                                                className="d-flex justify-content-between align-items-center"
                                                style={{ textDecoration: "none", fontWeight: "bold" }}>
                                                <span style={{ marginLeft: "5px" }}>{item.title}</span>
                                                <span title="Υποβολή" role="button" tabindex="0" className="btn btn-primary btn-sm">
                                                    <span>
                                                        <i className="fa fa-pencil"></i>&nbsp;
                                                    </span>
                                                </span>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>


            </Tab>
           

            




    </Tabs>


        
    )
}

export { NewFormGridUsers }